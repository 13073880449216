import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Container, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";

const Header = () => {

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: 'white', // Set the navbar to white
        color: 'black',            // Set the text color to black
        boxShadow: 'none',         // Remove default shadow for a flat design
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo Image for Larger Screens */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 2, flexGrow: 1 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component={Link} to="/">
              Mariko Kumanomido
            </Typography>
          </Box>

          {/* Logo Image for Smaller Screens */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'center' }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component={Link} to="/">
              Mariko Kumanomido
            </Typography>
          </Box>

          {/* Hamburger Menu for Smaller Screens */}
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Button color="inherit" component={Link} to="/cv-resume">CV/Resume</Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button color="inherit" component={Link} to="/choreography">Choreography</Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button color="inherit" component={Link} to="/performance">Performance</Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button color="inherit" component={Link} to="/teaching-philosophy">Teaching</Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button color="inherit" component={Link} to="/contact">Contact</Button>
              </MenuItem>
            </Menu>
          </Box>

          {/* Navigation Links for Larger Screens */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button color="inherit" component={Link} to="/cv-resume">CV/Resume</Button>
            <Button color="inherit" component={Link} to="/choreography">Choreography</Button>
            <Button color="inherit" component={Link} to="/performance">Performance</Button>
            <Button color="inherit" component={Link} to="/teaching-philosophy">Teaching</Button>
            <Button color="inherit" component={Link} to="/contact">Contact</Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
