import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import Header from "./HeaderNew";
import Footer from "./Footer";
import Home from "./Home";
import Choreography from "./Choreography";
import CVResume from "./CVResume";
import Performance from "./Performance";
import TeachingPhilosophy from "./Teaching";
import ContactForm from "./Contact";
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/choreography" element={<Choreography />} />
          <Route path="/cv-resume" element={<CVResume />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/teaching-philosophy" element={<TeachingPhilosophy />} />
          <Route path="/contact" element={<ContactForm />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
