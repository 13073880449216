import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4A148C', // A deep purple color for buttons, headers, and accents
      contrastText: 'black', // White text on primary buttons
    },
    secondary: {
      main: '#F9A825', // Golden-yellow for highlights and accents
      contrastText: '#000000', // Black text for secondary elements
    },
    background: {
      default: '#F5F5F5', // Light gray for the website background
      paper: '#FFFFFF', // White for cards and surfaces
    },
    text: {
      primary: '#212121', // Dark gray for main text
      secondary: '#757575', // Lighter gray for subtitles or secondary text
    },
  },
  typography: {
    fontFamily: '"helvneu", "Roboto", "Arial", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: '0.1rem',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      letterSpacing: '0.05rem',
      textTransform: 'capitalize',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: '0.02rem',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.45rem',
      fontWeight: 400,
      color: 'black',
      textDecoration: 'none',
      fontWeight: '700',
      lineHeight: '1.45rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.6,
    },
    button: {
      fontSize: '13px',
      letterSpacing: '1.2px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'helvneu';
          src: url('/fonts/HelveticaNeueLight.otf') format('opentype');
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Rounded corners for buttons
          textTransform: 'none', // Prevent all-uppercase text
          padding: '10px 20px',
        },
        containedPrimary: {
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#38006b', // Darker purple on hover
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: 'white', // Darker yellow on hover
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Deep purple AppBar
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: '#4A148C', // Use primary color for main headings
        },
        h2: {
          color: '#4A148C',
        },
        h3: {
          color: '#4A148C',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Soft rounded edges for cards
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
    },
  },
});

export default theme;
