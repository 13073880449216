import React from "react";
import { Box, Typography } from "@mui/material";
import Banner from './images/croppedBanner1.jpg';

const Choreography = () => {
  return (
    <Box sx={{ textAlign: "center", py: 0 }}>
      <img
        src={Banner}
        alt="Mariko Kumanomido"
        style={{ width: "100%", objectFit: 'cover', opacity: '1.0' }}
      />
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
        <Typography variant="h6" sx={{ flexGrow: 1 }} className="pageTitle">
          Choreography
        </Typography>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'left', margin: '0px 30px'}}>
          {
            pieces.map(p => {
              return <SinglePiece piece={p} />
            })
          }
        </div>
      </div>
    </Box>
  );
};

const SinglePiece = props => {
  const {
    title,
    year,
    choreographer,
    performer,
    linkid
  } = props.piece

  return (
    <div className="rowflex" style={{margin: '10px 0px', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%'}}>
      <div className="colflex" style={{marginRight: '40px', minWidth: '20%', flex: '1'}}>
        <div className="rowflex" style={{margin: '4px 0px'}}>
          <span className="pieceMeta">Title:</span>
          <span className="pieceValue">{title}</span>
        </div>
        <div className="rowflex" style={{margin: '4px 0px'}}>
          <span className="pieceMeta">Year:</span>
          <span className="pieceValue">{year}</span>
        </div>
        <div className="rowflex" style={{margin: '4px 0px'}}>
          <span className="pieceMeta">Choreography:</span>
          <span className="pieceValue">{choreographer}</span>
        </div>
        <div className="rowflex" style={{margin: '4px 0px'}}>
          <span className="pieceMeta">Performers:</span>
          <span className="pieceValue">{performer}</span>
        </div>
      </div>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${linkid}`}
        title={`${title} YouTube video player`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen>
    </iframe>
    </div>
  )
}

export default Choreography;

const pieces = [
  {
    title: "Dust",
    year: "2021",
    choreographer: "Mariko Kumanomido",
    performer: "Lindenwood University Dancers",
    linkid: "wYAxHOHsPyo"
  },
  {
    title: "Shiki (Four Seasons) - MFA Thesis Concert",
    year: "2018",
    choreographer: "Mariko Kumanomido",
    performer: "MFA Thesis Cast and Mariko Kumanomido",
    linkid: "fZr91XorJ-8"
  },
  {
    title: "Tori No Odori",
    year: "2017",
    choreographer: "Mariko Kumanomido",
    performer: "Mariko Kumanomido",
    linkid: "mfcUeMj5eU4"
  },
  {
    title: "Tori No Odori",
    year: "2017",
    choreographer: "Mariko Kumanomido",
    performer: "WashU Dance Collective",
    linkid: "b3LP0zC1AT4"
  },
  {
    title: "MFA Thesis Concert Rehearsal",
    year: "2017",
    choreographer: "Mariko Kumanomido",
    performer: "MFA Thesis Cast",
    linkid: "JLfmmQFY44E"
  }
]
