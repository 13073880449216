import React from "react";
import { Box, Typography } from "@mui/material";
import Banner from './images/croppedBanner1.jpg';

const Teaching = () => {
  return (
    <Box sx={{ textAlign: "center", py: 0 }}>
      <img
        src={Banner}
        alt="Mariko Kumanomido"
        style={{ width: "100%", objectFit: 'cover', opacity: '1.0' }}
      />
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
        <Typography variant="h6" sx={{ flexGrow: 1 }} className="pageTitle">
          Teaching Philosophy
        </Typography>
        <div className="CVItems" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', margin: '0px 30px'}}>
          <p>
            A friend of mine, who is also a dance teacher, told me that she was teaching a beginning ballet class to 7 year olds. She was teaching port de bras (movement of the arms), and she asked, “Why do we port de bras?” One student answered enthusiastically, “For the Joy!”
          </p>
          <p>
            This young girl's answer beautifully sums up my feelings on dance as well. Why do we dance? Why do I dance? Why do I teach dance? For the joy! I love dancing, the way it makes me feel alive, connected to myself, and to the greater Universe. This enjoyment of dancing has led me on my career path, and is what made me choose to study dance, and to become a dancer and dance teacher as my vocation.
          </p>
          <p>
            As a ballet and modern (Horton and Graham techniques) dance teacher, I try to impart my classes with this sense of joy. I try to create an environment where students can experience this sense of joy in movement, while developing the technical skills required of a skilled dancer. Teaching technique and developing technical mastery are very important to me as a teacher, as I believe that technique (in ballet, Horton and Graham) enables the dancer to ultimately have more freedom of expression, and therefore more joy in their dancing. Developing physical discipline as well as mental concentration, and greater somatic awareness are skills I try to impart to my students, as I believe that these skills are vital to a dancer. Maintaining an environment of respect, openness, and safety is very important to me, as I try to make students feel comfortable to be themselves, take risks, try new things, make mistakes, and try again. I believe that when a student knows it is safe to make a mistake, they are more willing to freely explore, perform, and dance from a genuine place. My teaching philosophy centers on these core ideas: fostering joy through movement, building strong dance technique, developing strong concentration and awareness, and creating a safe space where students feel free to take risks, and explore their movement potential.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Enjoyment in Dancing
          </Typography>
          <p>
            I try to bring my own passion for dance into the classroom, hoping to cultivate passion for dance in my students. I approach teaching whole-heartedly, concentrating all of my energy and focus into what I am doing in the classroom. I believe that my own interest in dance and in the specific class sets the tone for the students. I think that when they see I am fully invested in the work, it naturally draws them in as well. One way I bring my whole self to the classroom is by showing my emotional investment in the work. If students succeed at a given task, I show my excitement and joy in their success. If they are struggling, I encourage them, push them, and offer them other approaches to the movement.
          </p>
          <p>
            I do my best to maintain a positive, happy attitude in the classroom, regardless of what my previous experiences of the day. Whether I am tired, upset, or scattered, when I enter the classroom, I do my best to have a clear, positive presence, and optimistic attitude. Again, I believe the teacher sets the tone for the students, and I think that my positive attitude helps the students to feel uplifted as well. One way I do this is by using humor. I sometimes relate anecdotes of my own struggles as a dancer, often laughing at myself. For example, if I am demonstrating and teaching a physically challenging exercise in a Horton class, I might say something like, “The first time I did this exercise, I felt like I was going to fall on the floor, so it’s normal if you feel like that.” Making light of the difficulty in dance helps the students have fun, and remain positive, despite the challenges they encounter.
          </p>
          <p>
            In keeping with my theme of cultivating joy through dance, I to remind my students to have fun in class. Even performing simple exercises such as plies and tendus, I encourage them to think of as dancing, rather than just doing an exercise. I tell them to find their “dance spirit,” meaning the sense of “flow”, aliveness, and excitement in what they are doing. In certain classes, I also include improvisation as a way to cultivate joy in movement. It help students cultivate a “flow” when they move, as well as builds the skills of improvisation. When students improvise, they move in a way that feels natural for them, and helps them cultivate their own sense of their body and their own movement dynamic. For example, I might give a progression of dance exercises, such as a tendu pattern, which I will tell the students to repeat as dégagés. But in between the two exercises, I tell them to improvise for 32 counts. I insert this improv break to help the students warm-up physically, learn the skills of improvisation, and to help them have fun.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Technique
          </Typography>
          <p>
            As a ballet and modern dance teacher, my primary role is to teach students how to dance, to teach ballet and modern dance technique. A good sense of movement is a natural talent for many dancers and some people are just born with bodies that are suited to dance, with good coordination and musicality. But in the dance profession, one also needs to learn the craft and the skills of dance. Proper understanding of how to use the body and physical mastery of certain movements are vital to becoming a dancer, whether the dancer aspires to be a professional, or just takes dance class for fun. Proper alignment and use of the muscles is important, not only for aesthetic reasons, but for the health and safety of the dancer as well. For this reason, I focus a lot on proper technique in dance class. Having correct alignment of the spine, of the pelvis, knees, and feet, correct use of the arms, and correct use of the muscles are crucial. When a student uses her body correctly, she is able to dance with greater ability, and have more technical prowess, as well as less chance of injury.
          </p>
          <p>
            In my classes, I try to make sure students have the fundamentals of proper alignment, use of core, and proper understanding of the given exercises. For example, in a beginning ballet class, I will concentrate on slow tendus, making sure that the knees are straight, center is engaged, the spine is lengthened, muscles are engaged on the supporting side, and foot is pointed, and that the foot does not lose contact with the floor. Mastering this skill of tendus is very important at the beginner level, as so much dance technique is built from the tendu. I believe that if a dancer has a good foundation, she can grow and become successful. However, if the basics of technique are not there, it is very hard and dangerous to move ahead technically.
          </p>
          <p>
            Once the basics are established, I try to build more advanced work into the class, but only after a good foundation has been laid. Even in intermediate and advanced classes, I find that there is a constant refining of the basic principles, at a more subtle level. Thus, even at an advanced level, I try to have the advanced dancer examine and refine the basics of alignment, correct muscle use, and coordination, in combination with complexity of movement.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Wholistic Skill Development
          </Typography>
          <p>
            I believe that cultivating a dancer’s mind is important as well, not only the body. To be a good dancer, one must have strong concentration, awareness, focus, diligence, be intelligent, have perseverance, patience, and determination. I try to help my students have these qualities. I remind them to concentrate, and to think ahead, while being in the present moment. For example, I like to make combinations with lots of changes of weight, from standing on the right foot to the left foot, back to the right foot again, as it helps dancers learn how to shift weight quickly while dancing. I remind them to anticipate the change of weight, to concentrate in the exercise, and be mentally and physically prepared for the change of weight.
          </p>
          <p>
            I also encourage them to think about how they are moving through space, through positions, rather than just thinking of making particular shapes or positions. I think concentrating on the how, encourages them to be aware within their dancing. When they are aware of what they are doing, they then become their own best teacher. They can self-correct, and refine their own technique, and be aware of what is happening in their own bodies. Cultivating somatic awareness and the ability to self-correct are invaluable skills to a dancer.
          </p>
          <p>
            In helping a dancer grow, I also try to encourage them have a positive relationship with themselves. I have found that many people are very self-critical, including myself, but that this sense of self-criticism can stifle one’s growth. Therefore, I encourage students not to get too frustrated when they cannot accomplish something difficult for them. I remind them that learning anything is always a process, and sometimes, in the beginning of the process, one has a lot of difficulty, and it’s a struggle. But as time goes on, with practice, we get better and better at the given task, and it eventually gets easy. I remind the students that they are in process, and not to expect them to be a perfect finished product. I also tell my students of how far they have come, and point out to them evidence of the process in themselves. I might say something like, “That exercise was very good. Look at how far you have come from the beginning of the semester. Remember how difficult this was for you before, now look at how well you just performed it.” This also teaches perseverance. If a student sees that hard work and practice pay off, despite challenges, they are more willing to keep trying, and to be more hopeful and persistent. I hope that understanding this principle of process and persistent hard work can serve them in a wide range of aspects in life, from school work, relationships, and any passion they pursue.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            A Supportive Environment
          </Typography>
          <p>
            I care about my students as human beings first, and second as dancers. I believe that dance is an expression of our humanity. As a teacher, I try to remember that my sense of humanity, caring for others, and compassion is the most important teaching I can transmit to my students, even more important than dance technique. This is where the sense of joy comes from, being in touch with our true selves through dancing. Therefore, to be in touch with this sense of joy, we have to maintain contact with our inherent worth as humans. I try to teach my students with the attitude of love and respect for them as they are, and let them know that I value them as human beings, regardless of their performance in class. This attitude of unconditional acceptance, actually leads them to be better dancers, I believe, because they feel supported, encouraged, free to take risks, explore, go for it, fall, and try again, as they know that I care about them as people.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Conclusion
          </Typography>
          <p>
            I love dancing, performing, and choreographing, but I feel that I have the most impact as a teacher. Whether a dance student goes on to become a professional dancer or not, I believe that being in my classroom impacts them in a positive way. My students come to understand the value of concentration, hard work, and determination, despite challenges. They also gain the technical skills to advance them into the next level. And for my students, dance class is a good place to have fun, and not be concerned with stress. Whatever they get from the experience, I hope it benefits them, and adds joy to their lives.
          </p>
        </div>
      </div>
    </Box>
  );
};

export default Teaching;
