import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import TopPhoto from './images/bannercropped.jpg';
import ProfilePhoto from './images/marikoProfile.jpg';

const Home = () => {
  return (
    <Box sx={{ textAlign: "center", py: 0 }}>
      <img
        src={TopPhoto}
        alt="Mariko Kumanomido"
        style={{ width: "100%", objectFit: 'cover' }}
      />
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
        <Typography variant="h6" sx={{ flexGrow: 1 }} className="pageTitle">
          About Mariko
        </Typography>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', flexWrap: 'wrap'}}>
          <img
            src={ProfilePhoto}
            alt="Mariko Kumanomido Bio"
            style={{ border: '1px solid #313131', borderRadius: '3px', margin: '0px 50px 50px 50px' }}
          />
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left'}} className="biotext">
            <p style={{marginTop: '0px'}}>Mariko, originally from St. Louis, Missouri, studied at Alexandra School of Ballet under Alexandra Zaharias. She attended Washington University in St. Louis for two years, where she studied dance with Christine O'Neal, Mary-Jean Cowell, David Marchant, and Cecil Slaughter, and finished her degree at University of Missouri–Kansas City, where she trained with Paula Weber, Jennifer Medina, and Michael Simms, earning her BFA in Dance.</p>
            <p>She moved to New York City, and received a Fellowship at the Ailey School. In New York she danced professionally with Buglisi Dance Theater, Jessica Danser/Dansfolk, and C.Eule Dance. In 2010–2011 she studied at the Martha Graham Center of Contemporary Dance, training in Graham Technique, and completing the Graham Teacher Training Program, and performing with Graham II. While in NYC, she also taught beginning ballet and creative movement at Ballet Hispanico.</p>
            <p>After moving back to St. Louis in 2011, Mariko danced with Common Thread Contemporary Dance Co, under the direction of Jennifer Medina. In May 2018 Mariko completed her Master's of Fine Arts in Dance at Washington University in St. Louis. Mariko was also Adjunct Faculty at Webster University, Washington University in St. Louis, Lindenwood University and University of Missouri - St. Louis. She also teaches at St. Louis Ballet and COCA. She is passionate about teaching and dancing, and is thankful for the love and support of family and friends.</p>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Home;
