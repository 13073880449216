import React from "react";
import { Box, Typography } from "@mui/material";
import Banner from './images/croppedBanner1.jpg';

const CV = () => {
  return (
    <Box sx={{ textAlign: "center", py: 0 }}>
      <img
        src={Banner}
        alt="Mariko Kumanomido"
        style={{ width: "100%", objectFit: 'cover', opacity: '1.0' }}
      />
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
        <Typography variant="h6" sx={{ flexGrow: 1 }} className="pageTitle">
          CV/Resume
        </Typography>
        <div className="CVItems" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', textAlign: 'left', margin: '0px 30px'}}>
          <Typography variant="h6" sx={{ margin: '0px 0px 20px 0px' }}>
            Teaching
          </Typography>
          <p>
            <strong>2019 – Present</strong> – New City School, Creative Movement ages 4-6, St. Louis, MO.
          </p>
          <p>
            <strong>2019 – Present</strong> – Lindenwood University, Ballet, Modern Dance, Dance Composition, Dance Appreciation, Dance History, Adjunct Faculty, St. Louis, MO.
          </p>
          <p>
            <strong>2015 – Present</strong> – Vitality Ballet, Senior Ballet, St. Louis, MO.
          </p>
          <p>
            <strong>2011 – Present</strong> – COCA, Center for Creative Arts, Ballet and Modern Dance, St. Louis, MO.
          </p>
          <p>
            <strong>2013 – 2016</strong> – University of Missouri St. Louis, Modern Dance, Adjunct Faculty.
          </p>
          <p>
            <strong>2012 – Present</strong> – Webster University, Modern Dance, Adjunct Faculty, St. Louis, MO.
          </p>
          <p>
            <strong>2011 – Present</strong> – Washington University in St. Louis, Modern, Adjunct Faculty.
          </p>
          <p>
            <strong>2011 – Present</strong> – St. Louis Ballet School, Pre Ballet, Pre Tap, Contemporary, St. Louis, MO.
          </p>
          <p>
            <strong>2007 – 2011</strong> – School of Ballet Hispanico, Ballet and Creative Movement, New York City.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Dance Companies & Performances
          </Typography>
          <p>
            <strong>2016 – 2018</strong> – Washington University in St. Louis, MFA in Dance.
          </p>
          <p>
            <strong>2018</strong> – Dance for Food, “Woman – (like),” choreographed by Rachael Servello, dancer.
          </p>
          <p>
            <strong>2017</strong> – Washington University Dance Ensemble, guest performer, “Tori no Odori,” dancer.<br/>
            Washington University Student Dance Showcase, “Orbit,” dancer.<br/>
            American College Dance Association, 2017 Central Region Conference, “Grounded Dove,” choreographed by Mariko Kumanomido, dancer, and “Poemes de Prevert,”choreographed by Dawn Karlovsky, dancer.
          </p>
          <p>
            <strong>2016</strong> – Washington University Dance Theater, “Home Leaving,” improvised composition directed by David Marchant.
          </p>
          <p>
            <strong>2011 – Present</strong> – Common Thread Dance Company, St. Louis, MO. Performances:<br/>
            New Horizons Dance Festival 2012, “Anonymous,” choreographed by Jessica Lange;<br/>
            Spring to Dance 2012, “Women of the Cove,” choreographed by Jennifer Medina;<br/>
            Spring to Dance 2013, “Fields in Eternity,” choreographed by Jennifer Medina;<br/>
            Spring to Dance 2014, “Only One,” choreographed by Jennifer Medina;<br/>
            Spring to Dance 2015, “Cognitive Dissonance,” choreographed by Jennifer Medina;<br/>
            Arete 2015, choreographed by Jennifer Medina and Vance Baldwin.
          </p>
          <p>
            <strong>2010 – 2011</strong> – The Martha Graham Ensemble, New York City, NY.<br/>
            Performances: Appalachian Spring, Steps in the Street, Excerpts of Rite of Spring, Helios.
          </p>
          <p>
            <strong>2008 – 2011</strong> – C.Eule Dance Company, New York City, NY.<br/>
            Performances: The Crane Wife, 2008, 2009.
          </p>
          <p>
            <strong>2007 – 2009</strong> – Buglisi Dance Theater, New York City, NY.<br/>
            Performances: Buglisi 15th Anniversary Season, Joyce Theater; Creative Residency and Performance at<br/>
            Kaatsbaan International Dance Center; Under the Buttonwood Tree, Wall Street, New York City, NY.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Choreography
          </Typography>
          <p>
            <strong>2021</strong> – “Dust” – Fall Dance Concert, Lindenwood University.
          </p>
          <p>
            <strong>2018</strong> – “Four” – MFA Thesis Concert, Washington University in St. Louis.
          </p>
          <p>
            <strong>2017</strong> – “Grounded Dove,” presented at the American College Dance Association 2017, Central Region Conference, Wichita, KS.<br/>
            “Tori no Odori,” presented as part of Washington University Dance Ensemble’s spring show, Luminous.<br/>
            “Orbit,” presented in Washington University’s 2017 Student Dance Showcase.
          </p>
          <p>
            <strong>2016</strong> – Wilson School’s 6th Grade Graduation Performance.
          </p>
          <p>
            <strong>2006</strong> – “Pillars,” UMKC BFA Concert, selected for faculty show, “Wild is the Wind,” UMKC BFA Concert.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Education
          </Typography>
          <p>
            <strong>2016 – 2018</strong> – Washington University in St. Louis, MFA in Dance
          </p>
          <p>
            <strong>2016</strong> – Horton Pedagogy Workshop, presented by Anna Marie Forsythe and The Ailey School, in coordination with Center of Contemporary Arts, St. Louis, MO.
          </p>
          <p>
            <strong>2010 – 2011</strong> – Martha Graham Center of Contemporary Dance, Teacher Training Program, New York City, NY.
          </p>
          <p>
            <strong>2006 – 2007</strong> – The Alvin Ailey School, Fellowship Award, Ballet and Contemporary, Full Scholarship Student, New York City, NY.
          </p>
          <p>
            <strong>2004 – 2006</strong> – University of Missouri . Kansas City. BFA in Dance.
          </p>
          <p>
            <strong>2002 – 2004</strong> – Washington University in St. Louis. Dance Major.
          </p>
          <p>
            <strong>1998 – 2002</strong> – Alexandra School of Ballet, Chesterfield, MO.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Volunteering
          </Typography>
          <p>
            <strong>2015 – 2016</strong> – Neighborhood Houses. Teaching Dance to children ages 6-10, St. Louis, MO.
          </p>
          <p>
            <strong>2013 – 2014</strong> – Kids Place. Teaching Creative Movement to children, ages 6-10, St. Louis, MO.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Projects
          </Typography>
          <p>
            <strong>2018</strong> – Performance at CAM, “Family Fun” Program, dancer, educator.
          </p>
          <p>
            <strong>2016</strong> – Collaboration with St. Louis Artists Guild, Parallel Chords, dancer.
          </p>
          <p>
            <strong>2015</strong> – Collaboration with St. Louis Artists Guild, Black Angels, dancer.
          </p>
          <p>
            <strong>2013</strong> – Collaboration with St. Louis Artists Guild, Mote et Renaissance, dancer.
          </p>
          <Typography variant="h6" sx={{ margin: '40px 0px 20px 0px' }}>
            Conferences
          </Typography>
          <p>
            <strong>2018</strong> – NDEO – National Conference, San Antonio, TX.
          </p>
          <p>
            <strong>2017</strong> – ACDFA – Central Conference, Wichita State University.
          </p>
        </div>
      </div>
    </Box>
  );
};

export default CV;
